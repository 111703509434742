import React, { useEffect, useState } from 'react';
import { EllipsisVerticalIcon, ArrowRightOnRectangleIcon, LockClosedIcon, QuestionMarkCircleIcon, RectangleStackIcon, BellIcon, AtSymbolIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import restClient from '../restClient';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ChangePasswordModal from './ChangePasswordModal';
import SessionModal from './SessionModal';
import ConnectionModal from './ConnectionModal';
import NotificationModal from './NotificationModal';
import EmailModal from './EmailModal';
import { useModal } from '../contexts/ModalContext';
import Avatar from '../components/Avatar';


const ProfileModal = ({ id = 'profileModal' }) => {
  const { openModal, closeModal, isTopModal } = useModal();
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await restClient.get('/user');
        setUser(response.data);
      } catch (error) {
        console.error('Error getting username:', error);
      }
    };
    if (!isTopModal(id)) getUser();
  }, [isTopModal]);

  const handleLogout = async () => {
    try {
      const response = await restClient.get('/logout');
      toast.success(response.data);
      navigate('/login');
      closeModal();
    } catch (error) {
      toast.error(error.response?.data || 'ارتباط برقرار نیست.');
      console.error('Logout failed:', error);
    }
  };

  const handleChangePassword = async () => {
    await closeModal();
    openModal('changePasswordModal');
  };

  const handleSession = async () => {
    await closeModal();
    openModal('sessionModal')
  };

  const handleConnection = async () => {
    await closeModal();
    openModal('connectionModal')
  };

  const handleNotification = async () => {
    await closeModal();
    openModal('notificationModal')
  };
  const handleAddEmail = async () => {
    await closeModal();
    openModal('addEmail');
  };

  if (!isTopModal(id)) {
    return (
      <div className="my-auto h-fit mx-[-8px]">
        {user?.hasNotification && <span className='select-none rounded-full w-2 h-2 bg-orange-300 absolute md:left-4 left-2' />}
        <EllipsisVerticalIcon className="w-8 cursor-pointer text-stone-600 rounded-md hover:bg-zinc-100" onClick={() => openModal(id)} />
        <ChangePasswordModal />
        <SessionModal />
        <NotificationModal />
        <EmailModal currentEmail={user.email} />
        <ConnectionModal />
      </div>
    );
  }

  return (
    <>
      <div className="my-auto h-fit mx-[-8px]">
        <EllipsisVerticalIcon
          className="w-8 cursor-pointer text-stone-600 rounded-md bg-zinc-200"
          onClick={closeModal}
        />
        <div className="fixed inset-0 z-50 flex items-start justify-end" onClick={closeModal}>
          <ul className="fixed left-2 md:left-4 top-[75px] bg-white rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 z-10 caret-transparent">
            <li className="flex items-center min-w-44 max-w-56 justify-end px-4 py-2 gap-3 border-b border-gray-200 cursor-default" onClick={(e) => e.stopPropagation()}>
              <Avatar username={user.username} className='max-w-36'/>
            </li>
            <li className='flex items-center gap-2 px-2 py-2 hover:bg-blue-50 transition-colors hover:cursor-pointer text-gray-600 text-sm' onClick={handleNotification}>
              <BellIcon className='h-5 w-5 mr-2' />
              {user?.hasNotification && <span className='w-2 h-2 bg-orange-400 rounded-full absolute mr-2.5 mb-3.5' />}
              <div>اعلان‌ها</div>
            </li>
            <li className='flex items-center gap-2 px-2 py-2 hover:bg-blue-50 transition-colors hover:cursor-pointer text-gray-600 text-sm' onClick={handleConnection} >
              <UserGroupIcon className="w-5 h-5 mr-2" />
              <div>همراهان</div>
            </li>
            <li className='flex items-center gap-2 px-2 py-2 hover:bg-blue-50 transition-colors hover:cursor-pointer text-gray-600 text-sm' onClick={handleSession} >
              <RectangleStackIcon className="w-5 h-5 mr-2" />
              <div>نشست‌های فعال</div>
            </li>
            <li className='flex items-center gap-2 px-2 py-2 hover:bg-blue-50 transition-colors hover:cursor-pointer text-gray-600 text-sm' onClick={handleChangePassword} >
              <LockClosedIcon className="w-5 h-5 mr-2" />
              <div>تغییر کلمه عبور</div>
            </li>
            <li className="flex items-center gap-2 px-2 py-2 hover:bg-blue-50 transition-colors hover:cursor-pointer text-gray-600 text-sm" onClick={handleAddEmail} >
              <AtSymbolIcon className="w-5 h-5 mr-2" />
              <div>افزودن ایمیل</div>
            </li>
            <li
              className="flex items-center gap-2 px-2 py-2 hover:bg-blue-50 transition-colors hover:cursor-pointer text-gray-600  text-sm" onClick={() => window.open('https://t.me/ListanSupport', '_blank', 'noopener,noreferrer')} >
              <QuestionMarkCircleIcon className="w-5 h-5 mr-2" />
              <div>پشتیبانی</div>
            </li>
            <li className='flex items-center gap-2 px-2 py-2 hover:bg-red-50 transition-colors hover:cursor-pointer text-red-400 rounded-b-xl  text-sm' onClick={handleLogout}>
              <ArrowRightOnRectangleIcon className="w-5 h-5 mr-2" />
              <div>خروج</div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProfileModal;
