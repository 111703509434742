import React, { useContext, useMemo } from "react";
import { useSwipeable } from "react-swipeable";
import DateObject from "react-date-object";
import { AppContext } from "../contexts/AppContext";
import { useModal } from '../contexts/ModalContext';

export const DateSwiper = ({ children }) => {
  const { setDate, onBookmark } = useContext(AppContext);
  const { isModalOpen } = useModal();

  const disabled = useMemo(() => isModalOpen() || onBookmark, [isModalOpen, onBookmark]);

  const updateDate = (changeType) => {
    setDate((prevDate) => {
      const dateObj = new DateObject(prevDate);
      return changeType === 'next' ? dateObj.add(1, "day") : dateObj.subtract(1, "day");
    });
  };

  const handlers = useSwipeable({
    onSwipedRight: () => !disabled && updateDate('next'),
    onSwipedLeft: () => !disabled && updateDate('previous'),
    delta: 125,
    touchAngle: 25,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true,
  });

  return (
    <div {...handlers} className="flex-grow flex flex-col" style={{ touchAction: 'none' }}>
      {children}
    </div>
  );
};
