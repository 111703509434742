import { BookmarkIcon, BookmarkSlashIcon } from "@heroicons/react/24/outline";
import React, { useContext, useState } from "react";
import { AppContext } from "../contexts/AppContext";

const BookmarkToggle = () => {
    const { date, setDate, onBookmark, setOnBookmark } = useContext(AppContext);
    const [prevDate, setPrevDate] = useState();

    const handleBookmark = () => {
        if (!onBookmark) { setPrevDate(date); setDate() }
        else { setDate(prevDate); setPrevDate() }
        setOnBookmark(!onBookmark);
    }

    return (
        <div className="w-8 p-[2px] self-center cursor-pointer text-stone-600 rounded-md hover:bg-zinc-100" onClick={handleBookmark}>
            {!onBookmark ? <BookmarkIcon /> : <BookmarkSlashIcon />}
        </div>
    );
}

export default BookmarkToggle;