import React from "react";
import CircularProgress from "../components/CircularProgress";
import InputBox from "../components/InputBox";
import Notes from "../components/Notes";
import { AppProvider } from "../contexts/AppContext";
import { ModalProvider } from "../contexts/ModalContext";
import ProfileModal from "../modals/ProfileModal";
import { DateSwiper } from "../components/DateSwiper";
import DateViewModal from "../modals/DateViewModal";
import BookmarkToggle from "../components/BookmarkToggle";

const Home = () => {
  return (
    <AppProvider>
      <ModalProvider>
        <div className="flex flex-col h-screen">
          <div className="fixed w-full top-0 flex justify-between py-4 px-4 md:px-6 border-b bg-white z-10">
            <DateViewModal />
            <div className="flex md:gap-4 gap-2">
              <CircularProgress />
              <BookmarkToggle />
              <ProfileModal />
            </div>
          </div>
          <DateSwiper>
            <Notes />
          </DateSwiper>
          <InputBox />
        </div>
      </ModalProvider>
    </AppProvider>
  );
};

export default Home;
